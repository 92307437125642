@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-items: center;

  min-height: 100%;
  margin: 0;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

/* === BACKGROUND ===*/
.background {
  will-change: transform;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* === HEADER ===*/
.header {
  background: rgba(32, 32, 32, 0.75);
  backdrop-filter: blur(10px) brightness(0.9);
  box-shadow: 0 0 0.5rem rgba(32, 32, 32, 0.75);
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1920px;

  padding: 0.5rem;
  margin: auto;
}

.disclosure {
  color: #cbbeaf;
}

.social-links {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.social-link {
  display: block;
}

.social-link:hover,
.social-link:focus {
  filter: brightness(1.25);
}

.social-link img {
  display: block;
  width: 2em;
  height: 2em;
}

/* === HERO ===*/
.hero {
  margin: auto;
  padding-bottom: 4rem;

  width: 100%;

  text-align: center;
}

.logo {
  display: inline-block;

  text-align: center;
  height: auto;
  width: 51rem;
  max-width: 100%;
}

.buttons {
  margin: auto;

  display: grid;
  justify-content: center;
  grid-template-columns: min-content;
  gap: 1rem;
}

@media (min-width: 500px) {
  .buttons {
    grid-template-columns: repeat(2, min-content);
  }
}

.button {
  display: block;
  overflow: hidden;
  cursor: pointer;
  width: 200px;
  max-width: 100%;
  height: 50px;
  line-height: 50px;

  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 700;

  color: #fed;
  background: #552;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.75);
}

.button:hover,
.button:focus {
  filter: brightness(1.25);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.75);
}

/* === CONTENT ===*/
.content {
  overflow: hidden;

  color: #fff;
  background: rgba(32, 32, 32, 0.75);
  backdrop-filter: blur(10px) brightness(0.9);
  box-shadow: 0 0 0.5rem rgba(32, 32, 32, 0.75);
}

.text-video-combo {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 1rem 0;

  max-width: 80rem;
}

.text {
  flex: 1;
  min-width: 20rem;
  margin: 1rem;

  line-height: 1.5;
}

.text h3 {
  margin: 0;
  margin-bottom: 1rem;

  font-size: 1.4rem;
  font-weight: inherit;
  text-transform: uppercase;
}

.text p {
  padding: 0;
  margin: 0.5em 0;
}

.video-wrapper {
  flex: 1;
  min-width: 20rem;
  margin: 1rem;
}

.video {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.video iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
